<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-factory</v-icon
                  >{{ $vuetify.lang.t("$vuetify.factoryEdit") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <!-- Company Name -->
              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.companyName')"
                  v-model="myObj.company"
                  :rules="generalRule"
                />
              </v-col>
              <!-- contactPerson -->
              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.contactPerson')"
                  v-model="myObj.contact"
                  :rules="generalRule"
                />
              </v-col>
              <!-- Phone -->
              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.mobile')"
                  v-model="myObj.tel"
                  :rules="generalRule"
                />
              </v-col>
              <!-- Fax -->
              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.fax')"
                  v-model="myObj.fax"
                />
              </v-col>
              <!-- Address -->
              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.addr')"
                  v-model="myObj.address"
                />
              </v-col>
              <!-- mailbox -->
              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.email')"
                  v-model="myObj.email"
                  :rules="generalRule"
                />
              </v-col>
              <!-- Remark -->
              <v-col class="py-0" cols="12" lg="12" md="6" sm="6">
                <v-textarea
                  :label="$vuetify.lang.t('$vuetify.note')"
                  v-model="myObj.note"
                  outlined
                  height="100"
                ></v-textarea>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.save")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    myObj: Object,
    deleteObj: Object,
  },
  data() {
    return {
      valid: false,
       generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    cancelForm() {
        this.resetValidation();
      this.$emit("update:dialog", false);
    },
    validate() {
        this.$refs.form.validate() == true
        ? this.$emit("handleData", this.myObj)
        : console.log("false");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
            